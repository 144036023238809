<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1
                class="text-left"
                v-if="$route.params.type == 'Univ.Centers.Activity'"
              >
                {{ CollegeActivity }}
              </h1>
              <h1 class="text-left" v-else>{{ Department }}</h1>

              <div class="breadcrumb-bar">
                <ul class="breadcrumb text-left">
                  <li>
                    <router-link href="#" aria-label="title" to="/">{{
                      home
                    }}</router-link>
                  </li>
                  <li v-if="$route.params.type == 'Univ.Centers.Activity'">
                    {{ CollegeActivity }}
                  </li>
                  <li v-else>{{ Department }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="latest-area section-padding" style="padding: 50px 0 60px">
      <div class="container">
        <div class="row">
          <div
            v-for="event in Events"
            :key="event.postId"
            class="col-lg-4 col-md-6 col-12 events_top"
          >
            <div class="single-event-item">
              <div class="single-event-image">
                <a>
                  <img
                    loading="lazy"
                    alt=""
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' +
                      event.postImage
                    "
                  />
                </a>
              </div>
              <div class="single-event-text">
                <h6>
                  <a
                    aria-label="title"
                    style="line-height: 2; text-align: justify"
                    >{{ event.postTitle }}
                  </a>
                </h6>
                <div class="single-item-comment-view">
                  <span><i class="fa fa-clock-o"></i>{{ event.time1 }}</span>
                  <span
                    ><i class="fa fa-map-pin"></i>{{ event.txtAdress1 }}
                  </span>
                </div>
                <p style="text-align: justify">
                  {{ event.postSubTitle }}
                </p>

                <router-link
                  href="#"
                  aria-label="title"
                  v-if="$route.params.type == 'Univ.Centers.Activity'"
                  class="button-default"
                  :to="{
                    name: 'DepartmentDetails-with-BrnId-And-Type-And-id',
                    params: {
                      type: 'Univ.Centers.Activity',
                      id: event.postID,
                      BrnId: event.brnID,
                    },
                  }"
                >
                  {{ Detials }}
                </router-link>
                <router-link
                  href="#"
                  aria-label="title"
                  v-else
                  class="button-default"
                  :to="{
                    name: 'DepartmentDetails-with-BrnId-And-Type-And-id',
                    params: {
                      type: 'Univ.Faculty.Activity',
                      id: event.postID,
                      BrnId: event.brnID,
                    },
                  }"
                >
                  {{ Detials }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      Events: [],
      show: true,
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      Department: "",
      Detials: "",
      CollegeActivity: "",
    };
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            self.translate = self.ArTranslat;
            self.home = self.translate[0]["HeaderAndFooter"]["Home"];
            self.Department = self.translate[0]["Home"]["Department"];
            self.Detials = self.translate[0]["Home"]["Detials"];
            self.CollegeActivity = self.translate[0]["Home"]["CollegeActivity"];

            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            self.home = self.translate[0]["HeaderAndFooter"]["Home"];
            self.Department = self.translate[0]["Home"]["Department"];
            self.Detials = self.translate[0]["Home"]["Detials"];
            self.CollegeActivity = self.translate[0]["Home"]["CollegeActivity"];

            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();

        self.translate = self.ArTranslat;
        self.home = self.translate[0]["HeaderAndFooter"]["Home"];
        self.Department = self.translate[0]["Home"]["Department"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.CollegeActivity = self.translate[0]["Home"]["CollegeActivity"];

        self.getApis();
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.translate = self.EnTranslat;
        self.home = self.translate[0]["HeaderAndFooter"]["Home"];
        self.Department = self.translate[0]["Home"]["Department"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.CollegeActivity = self.translate[0]["Home"]["CollegeActivity"];

        self.getApis();
      }
    }
  },
  methods: {
    getApis() {
      var bodyFormData1 = new FormData();
      var self = this;
      bodyFormData1.append("check", "getPost");
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("FbrnId", this.$route.params.BrnId);
      bodyFormData1.append("Type", this.$route.params.type);
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          self.Events = response.data;
          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);

          setTimeout(() => {
            self.show = false;
            document.querySelectorAll("div.loader").forEach(function (element) {
              element.setAttribute("hidden", true);
            });
          }, 100);
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
};
</script>

<style scoped>
.events_top {
  margin-top: 50px;
}
</style>
